@charset 'UTF-8';
@import './setting/variables';

.GPC0067 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding-left:24px;
		padding-right:24px;
	}
	@extend %clearfix;
	.unit-box {
		float:left; 
		width:calc(100%/2 - 12px); 
		&:not(:last-child) {
			margin-right:24px;
		}
		[dir="rtl"] & {
			float:right;
			margin-right:0;
			&:not(:last-child) {
				margin-left:24px;
			}
		}
		.visual-area {
			position:relative; 
			width:100%;
			margin-bottom:19px;
			text-align:center;
			@include screen(custom, max, $max-sm) {
				margin-bottom:15px;
			}
			a {
				display:block;
			}
			img {
				max-width:100%;
			}
			&.video {
				&:before {
					width:64px;
					height:64px;
					margin:-32px 0 0 -32px;
					background-size:100%;
				}
			}
		}
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6, .title p  { /* LGEGMC-243 20200520 */
			margin-bottom:12px;
			font-size:24px;
			line-height:32px;
			@include font-family($font-semibold);
		}
		.body-copy {
			margin-bottom:24px;
			font-size:16px; 
			line-height:24px;
			color:$color-dimgray;
		}
		.place {
			display:block; 
			margin-bottom:19px;
			font-size:14px;
			line-height:20px;
			color:$color-carmine;
			@include font-family($font-bold);
		}
		.bottom-btn {
			.link-text {
				color:$color-nightrider;
			}
			.ico-right{
				&:after {
					background: url('/lg5-common-gp/images/common/icons/link-right-nightrider.svg') no-repeat 0 1px;
				}
			}
		
			&.btn-type-box {	
				.btn{
					margin:0 6px 10px 0;
				}
			}
			&.btn-type-text {	
				.link-text{
					margin:0 18px 11px 0;
				}
			}
			
		}

		@include screen(custom, max, $max-sm) {
			float:none;
			width:100%; 
			margin: 0 0 23px 0;
			&:last-child {
				margin-bottom:0;
			}
			.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6, .title p { /* LGEGMC-243 20200520 */
				margin-bottom:8px;
				font-size:20px;
				line-height:26px;
			}	
			.visual-area {
				&.video {
					&:before {
						width:48px;
						height:48px;
						margin:-24px 0 0 -24px;
					}
				}
			}		
		}

	}
	&.img3 {	
		.unit-box {
			width:calc(100%/3 - 16px); 
			@include screen(custom, max, $max-sm) {
				width:100%; 
			}
		}
	}
	&.img4 {	
		.unit-box {
			width:calc(100%/4 - 18px); 
			@include screen(custom, max, $max-md) {
				width:calc(100%/2 - 12px); 
				margin-bottom:40px;
				&:nth-child(2n) {
					margin-right:0;
					[dir="rtl"] & {
						margin-left:0;
					}
				}
			}
			@include screen(custom, max, $max-sm) {
				width:100%; 
				margin-bottom:30px;
			}
		}
		
	}

	&.align-left{
		.bottom-btn {
			&.btn-type-box {	
				.btn{
					margin:0 6px 10px 0;
				}
			}
			&.btn-type-text {	
				.link-text{
					margin:0 18px 11px 0;
				}
			}
		}
	}

	&.align-center{
		.bottom-btn {
			&.btn-type-box {	
				.btn{
					margin:0 3px 10px 3px;
				}
			}
			&.btn-type-text {	
				.link-text{
					margin:0 9px 11px 9px;
				}
			}
		}
	}

	&.align-right{
		.bottom-btn {
			&.btn-type-box {	
				.btn{
					margin:0 0 10px 6px;
				}
			}
			&.btn-type-text {	
				.link-text{
					margin:0 0 11px 18px;
				}
			}
		}		
	}	
}